import marquee from "vanilla-marquee";

document.addEventListener('DOMContentLoaded', () => {
  if ( ! document.getElementById('js-marquee')) {
    return;
  }

  new marquee(document.getElementById('js-marquee'), {
    speed: 100
  });
});

//480px以下のスマホ時だけ、高さを動的に適用
function setResponsiveHeight() {
  var div = document.getElementById('slide');
  if (window.innerWidth <= 480) {
    div.style.height = (div.clientWidth * 0.59 + 22) + 'px';
  } else {
    div.style.height = '';
  }
}

// 初期設定
setResponsiveHeight();

// ウィンドウリサイズ時にも適用
window.addEventListener('resize', setResponsiveHeight);

//slickスライドの設定
$(function(){
  $('.pageHeroSlide').on('init', function() {
    setTimeout(function() {
      $('#slide').css('visibility', 'visible');
    }, 1000);
  });
  $('.pageHeroSlide').slick({
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: true,
    centerPadding: '0px',
    dots: true,
    slidesToShow: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 481,
        settings: {
          slidesToShow: 1,
          variableWidth: false
        }
      }
    ]
  });
});
